import * as i0 from '@angular/core';
import { input, booleanAttribute, Component, HostBinding } from '@angular/core';
import { CrmTranslatePipe } from 'common-module/translate';
import { NzSpinComponent } from 'ng-zorro-antd/spin';
export { crmMapOptions, crmResolveOptionId } from 'common-module/core/types';
class CrmLoadingComponent {
  constructor() {
    this.size = input('large');
    this.tip = input('crm.loading.tip');
    this.spinning = input(true, {
      transform: booleanAttribute
    });
    this.fullscreen = input(false, {
      transform: booleanAttribute
    });
    this.hostClass = 'crm-loading';
  }
  get isFullscreen() {
    return this.fullscreen();
  }
  static {
    this.ɵfac = function CrmLoadingComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CrmLoadingComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmLoadingComponent,
      selectors: [["crm-loading"]],
      hostVars: 4,
      hostBindings: function CrmLoadingComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
          i0.ɵɵclassProp("crm-loading--fullscreen", ctx.isFullscreen);
        }
      },
      inputs: {
        size: [1, "size"],
        tip: [1, "tip"],
        spinning: [1, "spinning"],
        fullscreen: [1, "fullscreen"]
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 2,
      vars: 5,
      consts: [["nzSimple", "", 3, "nzSize", "nzSpinning", "nzTip"]],
      template: function CrmLoadingComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "nz-spin", 0);
          i0.ɵɵpipe(1, "crmTranslate");
        }
        if (rf & 2) {
          i0.ɵɵproperty("nzSize", ctx.size())("nzSpinning", ctx.spinning())("nzTip", ctx.tip() ? i0.ɵɵpipeBind1(1, 3, ctx.tip()) : null);
        }
      },
      dependencies: [NzSpinComponent, CrmTranslatePipe],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmLoadingComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-loading',
      imports: [NzSpinComponent, CrmTranslatePipe],
      template: `
    <nz-spin
      nzSimple
      [nzSize]="size()"
      [nzSpinning]="spinning()"
      [nzTip]="tip() ? (tip() | crmTranslate) : null"
    ></nz-spin>
  `
    }]
  }], null, {
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }],
    isFullscreen: [{
      type: HostBinding,
      args: ['class.crm-loading--fullscreen']
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CrmLoadingComponent };
