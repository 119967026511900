import { FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { crmResolveExpression, CrmUnsubscribeDirective, crmKillEvent, CrmBreakpointSpanDirective } from 'common-module/core';
import { compact } from 'lodash-es';
import * as i0 from '@angular/core';
import { input, output, inject, ViewContainerRef, effect, untracked, Component, ChangeDetectionStrategy, Injectable } from '@angular/core';
import { toObservable, takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { CrmLoadingComponent } from 'common-module/common';
import { CrmFormFragmentComponent } from 'common-module/form/fragments';
import { CrmFormStore } from 'common-module/form/store';
import { NzFormDirective } from 'ng-zorro-antd/form';
import { NzRowDirective } from 'ng-zorro-antd/grid';
import { combineLatest, switchMap, Subject, forkJoin, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';
const _forTrack0 = ($index, $item) => $item.name;
function CrmFormComponent_Conditional_0_For_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 4, 0);
    i0.ɵɵelement(2, "crm-form-fragment", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    let tmp_13_0;
    let tmp_14_0;
    const fragment_r3 = ctx.$implicit;
    const parent_r4 = i0.ɵɵreference(1);
    const config_r5 = i0.ɵɵnextContext();
    i0.ɵɵproperty("breakpointConfig", (tmp_13_0 = fragment_r3.layout == null ? null : fragment_r3.layout.span) !== null && tmp_13_0 !== undefined ? tmp_13_0 : 24)("offset", (tmp_14_0 = fragment_r3.layout == null ? null : fragment_r3.layout.offset) !== null && tmp_14_0 !== undefined ? tmp_14_0 : 0);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("parent", parent_r4)("fragment", fragment_r3)("form", config_r5.form)("data", config_r5.data == null ? null : config_r5.data[fragment_r3.name]);
  }
}
function CrmFormComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "form", 2);
    i0.ɵɵlistener("keydown.enter", function CrmFormComponent_Conditional_0_Template_form_keydown_enter_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.handleSubmit($event));
    });
    i0.ɵɵelementStart(1, "div", 3);
    i0.ɵɵrepeaterCreate(2, CrmFormComponent_Conditional_0_For_3_Template, 3, 6, "div", 4, _forTrack0);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    let tmp_3_0;
    let tmp_4_0;
    const config_r5 = ctx;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵclassMapInterpolate1("crm-form crm-form--", ctx_r1.mode(), "-mode");
    i0.ɵɵproperty("nzLayout", (tmp_3_0 = config_r5.layout == null ? null : config_r5.layout.type) !== null && tmp_3_0 !== undefined ? tmp_3_0 : "vertical");
    i0.ɵɵadvance();
    i0.ɵɵproperty("nzGutter", (tmp_4_0 = config_r5.layout == null ? null : config_r5.layout.gutter) !== null && tmp_4_0 !== undefined ? tmp_4_0 : 0);
    i0.ɵɵadvance();
    i0.ɵɵrepeater(config_r5.fragments);
  }
}
function CrmFormComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "crm-loading");
  }
}
const crmValidateForm = (form, updateOptions) => {
  Object.values(form.controls).forEach(control => {
    if (control instanceof FormGroup) {
      crmValidateForm(control, updateOptions);
    } else if (control instanceof FormArray) {
      control.controls.forEach(arrayControl => {
        if (arrayControl instanceof FormGroup) {
          crmValidateForm(arrayControl, updateOptions);
        } else {
          arrayControl.markAsDirty(updateOptions);
          arrayControl.updateValueAndValidity(updateOptions);
        }
      });
    } else {
      if (control.pending) {
        return;
      }
      control.markAsDirty(updateOptions);
      control.updateValueAndValidity(updateOptions);
    }
  });
};
const crmFormRequiredValidator = options => {
  const {
    error = 'crm.validator.error.required',
    validator = Validators.required
  } = options ?? {};
  return {
    name: 'required',
    validator,
    error
  };
};
const crmFormRepeatPasswordValidator = options => {
  const {
    name = 'invalid',
    error = 'crm.validator.error.passwordsDoNotMatch',
    passwordPath = 'password'
  } = options ?? {};
  return {
    name,
    validator: control => {
      if (!control.value) {
        return null;
      }
      const passwordControl = control.root?.get(passwordPath);
      if (!passwordControl) {
        return null;
      }
      if (control.value !== passwordControl.value) {
        return {
          [name]: true
        };
      }
      return null;
    },
    error
  };
};
const crmEmailValidatorPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';
const crmEmailValidator = options => {
  const {
    error = 'email',
    pattern = crmEmailValidatorPattern
  } = options ?? {};
  return control => {
    const {
      value
    } = control;
    if (!value) {
      return null;
    }
    const validateControl = new FormControl(value, Validators.pattern(pattern));
    if (validateControl.invalid) {
      return {
        [error]: true
      };
    }
    return null;
  };
};
const crmFormEmailValidator = options => {
  const {
    error = 'crm.validator.error.email',
    validator = Validators.email
  } = options ?? {};
  return {
    name: 'email',
    validator,
    error
  };
};
class CrmFormBuilder {
  constructor(config) {
    this.config = config;
  }
  build() {
    return {
      ...this.config,
      form: this.buildForm(this.config.fragments, this.config.data)
    };
  }
  buildForm(fragments, data) {
    return fragments.reduce((form, fragment) => {
      const {
        name,
        children: fragmentChildren = []
      } = fragment;
      const nameData = this.withData(data)?.[name];
      switch (fragment.type) {
        case 'data-input':
          form.addControl(name, this.getControl(fragment, nameData));
          break;
        case 'group':
          form.addControl(name, this.buildForm(fragmentChildren, nameData));
          break;
        case 'array':
          form.addControl(name, new FormArray(compact(this.getArray(fragment, data))));
          break;
      }
      return form;
    }, new FormGroup({}));
  }
  getControl(fragment, data) {
    const {
      config
    } = fragment.contentContext ?? {};
    if (!config) {
      return new FormControl(data);
    }
    const validators = (config.validators ?? [])?.map(({
      validator
    }) => validator);
    const asyncValidators = (config.asyncValidators ?? [])?.map(({
      validator
    }) => validator);
    const value = data ?? config.defaultValue;
    const control = new FormControl(value, {
      validators,
      asyncValidators
    });
    if (config.disabled) {
      crmResolveExpression({
        resolvable: config.disabled
      }).subscribe(disabled => {
        if (disabled) {
          control.disable();
        } else {
          control.enable();
        }
      });
    }
    return control;
  }
  getArray(fragment, data) {
    const {
      name,
      children = []
    } = fragment;
    const child = children[0];
    if (children.length !== 1 && !['data-input', 'group'].includes(child.type)) {
      throw new Error(`Only one child of type 'data-input' or 'group' is allowed for 'array`);
    }
    const nameData = this.withData(data)?.[name];
    if (Array.isArray(nameData) && nameData.length) {
      return nameData.map(value => {
        switch (child.type) {
          case 'data-input':
            return new FormControl(value);
          case 'group':
            return this.buildForm([child], value);
          default:
            return null;
        }
      });
    } else {
      return new Array(fragment.prefilled ?? 1).fill(null).map(() => {
        switch (child.type) {
          case 'data-input':
            return new FormControl();
          case 'group':
            return this.buildForm([child], {});
          default:
            return null;
        }
      });
    }
  }
  withData(data) {
    if (typeof data === 'object' && !!data) {
      return data;
    }
    return undefined;
  }
}
class CrmFormComponent extends CrmUnsubscribeDirective {
  constructor() {
    super();
    this.provider = input.required();
    this.data = input();
    this.mode = input('edit');
    this.formInitialized = output();
    this.formSubmitted = output();
    this.route = inject(ActivatedRoute);
    this.viewContainerRef = inject(ViewContainerRef);
    this.store = inject(CrmFormStore);
    this.config$ = combineLatest({
      provider: toObservable(this.provider),
      data: toObservable(this.data)
    }).pipe(switchMap(({
      provider,
      data
    }) => provider.getBuiltForm({
      route: this.route,
      viewContainerRef: this.viewContainerRef,
      data: data ?? {}
    })), tap(config => this.formInitialized.emit(config)), takeUntilDestroyed());
    this.config = toSignal(this.config$);
    effect(() => {
      const mode = this.mode();
      untracked(() => this.store.mode.set(mode));
    });
    this.destroyRef.onDestroy(() => this.provider().destroy());
  }
  handleSubmit(event) {
    crmKillEvent(event);
    this.formSubmitted.emit();
  }
  static {
    this.ɵfac = function CrmFormComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CrmFormComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmFormComponent,
      selectors: [["crm-form"]],
      inputs: {
        provider: [1, "provider"],
        data: [1, "data"],
        mode: [1, "mode"]
      },
      outputs: {
        formInitialized: "formInitialized",
        formSubmitted: "formSubmitted"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([CrmFormStore]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 2,
      vars: 1,
      consts: [["parent", ""], ["nz-form", "", 3, "class", "nzLayout"], ["nz-form", "", 3, "keydown.enter", "nzLayout"], ["nz-row", "", 3, "nzGutter"], ["crmBreakpointSpan", "", 3, "breakpointConfig", "offset"], [3, "parent", "fragment", "form", "data"]],
      template: function CrmFormComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, CrmFormComponent_Conditional_0_Template, 4, 5, "form", 1)(1, CrmFormComponent_Conditional_1_Template, 1, 0, "crm-loading");
        }
        if (rf & 2) {
          let tmp_0_0;
          i0.ɵɵconditional((tmp_0_0 = ctx.config()) ? 0 : 1, tmp_0_0);
        }
      },
      dependencies: [CrmFormFragmentComponent, CrmLoadingComponent, NzFormDirective, NzRowDirective, CrmBreakpointSpanDirective],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmFormComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-form',
      template: `
    @if (config(); as config) {
      <form
        nz-form
        class="crm-form crm-form--{{ mode() }}-mode"
        [nzLayout]="config.layout?.type ?? 'vertical'"
        (keydown.enter)="handleSubmit($event)"
      >
        <div nz-row [nzGutter]="config.layout?.gutter ?? 0">
          @for (fragment of config.fragments; track fragment.name) {
            <div
              #parent
              crmBreakpointSpan
              [breakpointConfig]="fragment.layout?.span ?? 24"
              [offset]="fragment.layout?.offset ?? 0"
            >
              <crm-form-fragment
                [parent]="parent"
                [fragment]="fragment"
                [form]="config.form"
                [data]="config.data?.[fragment.name]"
              ></crm-form-fragment>
            </div>
          }
        </div>
      </form>
    } @else {
      <crm-loading></crm-loading>
    }
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [CrmFormFragmentComponent, CrmLoadingComponent, NzFormDirective, NzRowDirective, CrmBreakpointSpanDirective],
      providers: [CrmFormStore]
    }]
  }], () => [], null);
})();
class CrmFormProvider {
  constructor() {
    this.initialized$ = new Subject();
    this.destroy$ = new Subject();
  }
  get inputData() {
    return this.data;
  }
  get form() {
    return this.crmForm?.form;
  }
  get formValue() {
    return this.form?.value;
  }
  get formRawValue() {
    return this.form?.getRawValue();
  }
  getFormConfig(options) {
    const {
      data = {},
      viewContainerRef,
      route
    } = options;
    this.data = data;
    this.route = route;
    this.viewContainerRef = viewContainerRef;
    return forkJoin({
      fragments: this.getFormFragments(options),
      data: this.getFormData(options),
      layout: this.getLayout()
    });
  }
  getBuiltForm(options) {
    return this.getFormConfig(options).pipe(map(config => this.buildForm(config)), tap(config => this.formInitialized(config)));
  }
  formInitialized(form) {
    this.crmForm = form;
    this.initialized$.next(form);
  }
  destroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.initialized$.complete();
  }
  buildForm(config) {
    return new CrmFormBuilder(config).build();
  }
  getFormFragments(_options) {
    return of([]);
  }
  getFormData(options) {
    return of(options.data ?? {});
  }
  getLayout() {
    return of({
      gutter: 16
    });
  }
  static {
    this.ɵfac = function CrmFormProvider_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CrmFormProvider)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CrmFormProvider,
      factory: CrmFormProvider.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmFormProvider, [{
    type: Injectable
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CrmFormBuilder, CrmFormComponent, CrmFormProvider, crmEmailValidator, crmEmailValidatorPattern, crmFormEmailValidator, crmFormRepeatPasswordValidator, crmFormRequiredValidator, crmValidateForm };
