import * as i0 from '@angular/core';
import { signal, Injectable } from '@angular/core';
class CrmFormStore {
  constructor() {
    this.mode = signal('edit');
  }
  static {
    this.ɵfac = function CrmFormStore_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CrmFormStore)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CrmFormStore,
      factory: CrmFormStore.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmFormStore, [{
    type: Injectable
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CrmFormStore };
