import { inject, ChangeDetectorRef, isSignal } from '@angular/core';
import { isObservable, of, EMPTY } from 'rxjs';
import { toObservable } from '@angular/core/rxjs-interop';
import { wrapIntoObservable } from 'ng-zorro-antd/core/util';
const crmDetectChangesFn = () => {
  const ref = inject(ChangeDetectorRef);
  return () => ref.detectChanges();
};

/* eslint-disable @typescript-eslint/naming-convention */
function crmExpression(inputs) {
  const {
    expression,
    source,
    initialValue,
    injector
  } = inputs;
  if (typeof expression === 'function') {
    return resolveExpression({
      expression: expression(source),
      initialValue,
      injector
    });
  }
  return resolveExpression({
    injector,
    initialValue,
    expression
  });
}
const resolveExpression = inputs => {
  const {
    expression,
    initialValue,
    injector
  } = inputs;
  let $ = null;
  if (isObservable(expression)) {
    $ = expression;
  }
  if (isSignal(expression)) {
    $ = toObservable(expression, {
      injector
    });
  }
  if ($ == null) {
    if (expression == null) {
      $ = initialValue ? of(initialValue) : EMPTY;
    } else {
      $ = wrapIntoObservable(expression);
    }
  }
  return $;
};
const crmMarkForCheckFn = () => {
  const ref = inject(ChangeDetectorRef);
  return () => ref.markForCheck();
};

/**
 * Generated bundle index. Do not edit.
 */

export { crmDetectChangesFn, crmExpression, crmMarkForCheckFn };
