import en from '@angular/common/locales/en';
import { enUS } from 'date-fns/locale';
import { en_US } from 'ng-zorro-antd/i18n';
const crmResolveOptionId = value => {
  if (typeof value === 'object') {
    return value.id;
  }
  return String(value);
};
const crmMapOptions = options => options.map(option => ({
  ...option,
  id: crmResolveOptionId(option.value)
}));

/**
 * Filter input type to be rendered in `FilterHeader` component
 */
var CrmFilterInputType;
(function (CrmFilterInputType) {
  CrmFilterInputType["search"] = "search";
  CrmFilterInputType["select"] = "select";
  CrmFilterInputType["multiselect"] = "multiselect";
  CrmFilterInputType["dateRange"] = "dateRange";
  CrmFilterInputType["date"] = "date";
  CrmFilterInputType["custom"] = "custom";
})(CrmFilterInputType || (CrmFilterInputType = {}));

/**
 * Default translate config object
 */
const defaultTranslateConfig = {
  loader: {
    remoteLocale: false,
    remoteEnvs: [],
    localeFolder: './assets/locales/',
    remoteLocalePath: 'content',
    remoteLocaleUrlParams: {
      key: 'i18n'
    }
  },
  locale: {
    defaultLocale: 'en',
    locales: {
      en: {
        ngLocale: en,
        nzLocale: en_US,
        dateFnsLocale: enUS
      }
    }
  },
  formatter: {
    date: {
      defaultFormat: 'P'
    }
  }
};

/**
 * Generated bundle index. Do not edit.
 */

export { CrmFilterInputType, crmMapOptions, crmResolveOptionId, defaultTranslateConfig };
